const onOffTreatment = {
  on: 'on',
  off: 'off',
};

const crFeatureKeys = {
  consumerTransactionSamplesAndTreatmentAreas:
    'consumer_transactionSamplesAndTreatmentAreas_frontend',
  howAlleWorksRedesign: 'consumer_howAlleWorks_redesign_web',
  landingPage: {
    botoxDayStandby: 'consumer_botoxDayStandby_frontend',
    consumerBotox23DayContentfulDownRedirect:
      'consumer_botox23_day_contentful_down_redirect',
    juvDayStandBy: 'consumer_juvDayStandBy_frontend',
  },
  security: {
    inactivityTimerModal: 'consumer_inactivityTimerModal_web',
  },
  stayTunedPopup: 'consumer_stayTunedPopup_web',
} as const;

const financingFeatureKeys = {
  showAlternateApprovalScreen: 'financing_alternateApprovalScreen_web',
  useFullWidthOnFinancingApplicationIframe:
    'financing_useFullWidthForApplication_web',
  showEstimatorInWallet: 'financing_showEstimatorInWallet_web',
} as const;

const growFeatureKeys = {
  landingPage: {
    layout: 'growLandingPage_layout_web',
  },
  offerActivation: {
    errorMessage: 'growOfferActivation_errorMessage_web',
  },
  practiceCampaignCard: {
    hideChatNow: 'grow_practiceCampaignCard_hideChatNow',
  },
  optInOffers: {
    consumerBotox50OfferGrowTestForceExpired:
      'consumer_botox_50_offer_growtest_force_expired_web',
    consumerBotox50OfferGrowTestForceNotStarted:
      'consumer_botox_50_offer_growtest_force_not_started_web',
    consumerBotoxCMPOfferGrowTestForceExpired:
      'consumer_botox_cmp_offer_force_expired_web',
    consumerBotoxCMPOfferGrowTestForceNotStarted:
      'consumer_botox_cmp_offer_force_not_started_web',
  },
} as const;

const optimizeRegFeatureKeys = {
  autofocusRegFields: 'consumer_registrationFieldsAutofocus_web',
  optInOfferInlineClaim: 'consumer_optInOfferInlineClaim_web',
} as const;

const optimizeRegTreatments = {
  [optimizeRegFeatureKeys.autofocusRegFields]: onOffTreatment,
  [optimizeRegFeatureKeys.optInOfferInlineClaim]: onOffTreatment,
} as const;

const pdFeatureKeys = {
  providerDirectoryShowFreeConsultation:
    'providerDirectory_showFreeConsultation_web',
  providerDirectoryGoogleReviews: 'providerDirectory_googleReviews_web',
  providerDirectoryConsultationOfferVariant:
    'providerDirectory_consultationOfferVariants_web',
  providerDirectoryProfilePageRedesign:
    'providerDirectory_profilePageRedesign_web',
  providerDirectorySearchResultsPerPage:
    'providerDirectory_searchResultsPerPage_web',
  providerDirectorySearchRadius: 'providerDirectory_searchRadius_web',
} as const;

// consumer acquisition squad
const caFeatureKeys = {
  providerAboutUs: 'consumer_providerProfileAboutUs_web',
  providerLanguages: 'consumer_providerProfileLanguages_web',
  permanent: {
    newProducts: 'consumer_newProductsPermanent_web',
    newTreatmentAreas: 'consumer_newTreatmentAreasPermanent_web',
  },
  dashboardRedesign: 'consumer_dashboardRedesign_web',
  profileProductCardCmsBrands: 'consumer_profileProductCardCmsBrands_web',
  authSyncSegmentFix: 'consumer_authSyncSegmentFix_web',
  aboutUsRedesign: 'consumer_aboutUsRedesign_web',
  landingWebRedesign: 'consumer_landingWebRedesign_web',
  routeBasedNotificationBanner: 'consumer_routeBasedNotificationBanner_web',
};

const rafFeatureKeys = {
  referralWideScale: 'refer_a_friend_wide_scale',
} as const;

const passTheLeadFeatureKeys = {
  passTheLeadInlineOfferClaim: 'ges1_passTheLeadExperiment_frontend',
  passTheLeadSettings: 'ges1_passTheLeadSettings_frontend',
  passTheLeadPatientTreatmentHistory:
    'consumer_passTheLeadTreatmentHistory_web',
};

const passTheLeadRegTreatments = {
  [passTheLeadFeatureKeys.passTheLeadInlineOfferClaim]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadSettings]: onOffTreatment,
  [passTheLeadFeatureKeys.passTheLeadPatientTreatmentHistory]: onOffTreatment,
};

export {
  crFeatureKeys,
  growFeatureKeys,
  optimizeRegFeatureKeys,
  optimizeRegTreatments,
  pdFeatureKeys,
  rafFeatureKeys,
  caFeatureKeys,
  passTheLeadFeatureKeys,
  passTheLeadRegTreatments,
  financingFeatureKeys,
};
